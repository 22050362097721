import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './FoundingMemberCarouselItem.scss';

import { customPropTypes } from '../../../util';

function FoundingMemberCarouselItem({ data: { logo } }) {
  return (
    <div className="FoundingMemberCarouselItem">
      <div className="logo-container">
        <img className="logo" src={logo.image.default.url} alt={logo.image.default.alt} />
      </div>
    </div>
  );
}

FoundingMemberCarouselItem.propTypes = checkExtraProps({
  data: PropTypes.shape({
    title: PropTypes.string,
    logo: customPropTypes.image,
  }).isRequired,
});

FoundingMemberCarouselItem.defaultProps = {};

export default memo(FoundingMemberCarouselItem);
